<template>
  <div>
    <portal to="page-top-title">CategoryGroup #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'categoryGroups-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/category-groups/${id}`" data-prop="group" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ group.name }}</column-info>
          <column-info title="Active">
            <badge-active :value="group.active"/>
          </column-info>
          <column-info title="Created At">{{ group.created_at }}</column-info>
          <column-info title="Updated At">{{ group.updated_at }}</column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'

export default {
  name: 'CategoryGroupsSingle',
  metaInfo () {
    return { title: 'CategoryGroup #' + this.id }
  },
  components: {
    BoxSingle,
    BadgeActive,
    Btn
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      group: {}
    }
  }
}
</script>
